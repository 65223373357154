:root{
  --ball-size: clamp(160px, 20vw, 500px);
}
.container{
    position: relative;
    display: flex;
    width:100%;
    height: 100vh;
    background: var(--color-bg);
    overflow: hidden;
    justify-content: center;
     align-items: center;
  }
  
  #particle1, #point1{  
    margin-top: 0;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: calc(40% - (var(--ball-size) / 2));
    top: calc(40% - (var(--ball-size) / 2));
  }
  
  #particle2, #point2{
    margin-top: 0;
    z-index: 9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: calc(80% - (var(--ball-size) / 2));
    top: calc(20% - (var(--ball-size) / 2));
  }
  #particle3, #point3{
    margin-top: 0;
    z-index: 9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: calc(53% - (var(--ball-size) / 2));
    top: calc(80% - (var(--ball-size) / 2));
  }
  #particle4, #point4{
    margin-top: 0;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: calc(24% - (var(--ball-size) / 2));
    top: calc(65% - (var(--ball-size) / 2));
  }
  #particle5{
    margin-top: 0;
    z-index: 9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: calc(20% - (var(--ball-size) / 2));
    top: calc(20% - (var(--ball-size) / 2));
  }
  #particle6{
    margin-top: 0;
    z-index: 9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: calc(75% - (var(--ball-size) / 2));
    top: calc(52% - (var(--ball-size) / 2));
  }
  #exhib{
    display: inline-block;
    z-index: 5;
    position: absolute;
    left: calc(55% - (clamp(150px, 12vw, 400px) / 2));
    top: calc(56% - (clamp(150px, 12vw, 400px) / 2));
  }
  .ball img{
    width: var(--ball-size);
    height: var(--ball-size); 
  }
  .ball svg{
    width: clamp(150px, 12vw, 400px);
    height: clamp(150px, 12vw, 400px); 
  }
  .ball{
      position: relative;
  }
  .submenu{
    position: absolute;
    display: flex;
    background-color: #fff;
    border-radius: 1rem;
    padding: .25rem;
    gap: .5rem;
    bottom: .5rem;
    left: .5rem;
  }
  .sub{
    width: 2.5rem;
    height: 2.5rem;
  }
  .main{
    display: flex;
  }
  *{
    font-family: "GT Alpina";
  }
  @media only screen and (max-width: 400px) {
    #particle2,#particle3,#particle5,#particle6{
        z-index: 0;
    }
}