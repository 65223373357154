
@import 'global/typo', 'global/colors', 'global/spacing';
@import 'templates/home', 'templates/particles', 'templates/influenza', 'templates/interviews', 'templates/polio', 'templates/tuberculosis';

*{
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar{
    width: .3rem;
    position: fixed;
    background-color: transparent;
}
::-webkit-scrollbar-track{
	background-color: rgb(216, 216, 216);
}
::-webkit-scrollbar-thumb
{
	background-color: var(--color-black);
}
#overlay{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99;
}

body{
    background: var(--color-bg);
    display: grid;
    width: 100%;
    grid-template-columns: 2fr auto;
    height: 100vh;
    position: fixed;
}
menu{
    display: grid;
    grid-template-columns: repeat(2, 33%);
    gap: .3rem;
    margin-bottom: var(--space-l);
}
menu a{
    text-decoration: underline;
    grid-column: 1;
}
.additional-links{
    grid-column: 2;
    grid-row: 1;
}
.additional-links:last-child{
    grid-row:2;
}
menu a:hover{
    font-style: italic;
}
.link{
    width: max-content;
    text-align: right;
}
.content{
    position: relative;
    display: flex;
    overflow-y: auto;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.title-interim{
    width: 75%;
    position: absolute;
    z-index: 2;
    pointer-events:none;
    user-select: none;
}
.title-interim h1:first-child{
    z-index: 10;
}
.title-iterim h1:first-child{
    z-index: 3;
}
.title-interim h1{
    text-align: left;
    margin-bottom: 5vw;
    padding: var(--space-xxs);
}
.title-interim h1:first-child{
    text-align: center;
}
.title-interim h1:last-child{
    text-align: right;
    margin: 0;
}
.sidebar{
    width: 0;
    max-width: 70ch;
    background: var(--color-bg);
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.24); 
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.24);
    height: 100vh;
    opacity: .6;
    transition: opacity ease-out .3s;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 998;
    scroll-behavior: smooth;
}
.sidebar-content{
    padding: var(--space-xxs) var(--space-xxs) var(--space-l) var(--space-xxs);
    width: calc(33vw - (var(--space-xxs) * 2));
    max-width: calc(70ch - (var(--space-xxs) * 2));
}

.sidebar:hover{
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.24); 
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.24);
    opacity: 1;
    transition: opacity ease-out .5s;
}
.info{
    position: fixed;
    top: var(--space-s);
    right: var(--space-s);
    font-family: var(--font-copy);
    font-style: italic;
    text-decoration: none;
    color: var(--color-bg);
    background: var(--color-black);
    padding: .3rem;
    text-align: center;;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    z-index: 999;
    align-self: center;
}

img{
    -webkit-user-drag: none;
}
.reference-img{
    max-width: 100%;
    margin-top: var(--space-xxs);
}

.image-border-radius{
    border-radius: var(--space-m);
    border: 1px solid var(--color-black);
    width: 100%;
    display: block;
    margin-top: var(--space-xs);
}
//@keyframes animation{
//    from{filter: blur(0px);}
//    to{filter: blur(4px);}
//}
.svg-test{
    transition: .2s filter ease-out, .2s -webkit-filter ease-out;
}
.svg-test:hover{
    filter: drop-shadow(0 0 .5rem rgb(255, 53, 53));
    transition: .2s filter ease-out, .2s -webkit-filter ease-out;
}
.project-home-title{
    position: absolute;
    background: #fff;
    padding: var(--space-xs) var(--space-xs);
    border-radius: calc(var(--space-xs) * 2);
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    cursor: pointer;
    user-select: none;
    width: max-content;
}
.ball:hover .project-home-title{
    display: block;
}
.content-text >*{
    padding-left:0;
}

.content-text-scrollable{
    overflow-y: scroll;
    overflow-x: hidden;
}
.content-text{
    width: calc(100% - calc(var(--space-m) * 2));
    margin-bottom: 5rem;
}
.full-video{
    max-width: 90vh;
}
.poster-image-holder{
    max-height: 100vh;
    max-width: 1000px;
    padding:2rem;
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.poster-image-evil{
    max-height: 100vh;
    width:100%;
}

//Quick Switch Menu
.quick-switch-menu{
    background-color: #fff;
    position: fixed;
    bottom: var(--space-xxs);
    left: var(--space-xxs);
    text-decoration: none;
    z-index: 9;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    border-radius: 2rem;
}
.quick-switch-menu a{
    text-decoration: none;
    float: left;
    margin-right: .3rem;
}
.quick-switch-menu a:first-child{
    margin-left: .3rem;
}
.quick-switch-link img{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    // background-color: #eee;
    padding: .3rem;
    display: block;
}
.quick-switch-link:hover img{
    transform: scale(1.15);
    filter: drop-shadow(0 0 .1rem yellow);
}
.content-smallpox{
    position: relative;
    display: flex;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.image-gallery{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:1.5rem;
    justify-content: space-around;
    align-content: space-around;
    width: calc(100% - 2rem);
    margin:3rem 0;
    padding:1rem;
}
.img-smallpox{
    width:100%;
    max-width: calc(100vh/1.2);
    place-self: center;
}

//Mobile Rules
@media only screen and (max-width: 960px) {
    .image-gallery{
        grid-template-columns:1fr 1fr;
    }
}
@media only screen and (max-width: 760px) {
    body {
      position: relative;
      grid-template-columns: 1fr;
      display: block;
      overflow: hidden;
    }
    .content{
        max-height:100vh;
        display: flex;
        position: relative;
    }
    .sidebar{
        overflow-y: scroll;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        width: 100%;
        max-width: 100%;
        z-index: 9;
    }
    .info{
        z-index:999;
    }
    .title-interim{
        padding: var(--space-xxs);
        width: 100%;
    }
    .sidebar-content{
        width: calc(100% - calc(var(--space-xxs)*2));
    }
    .content-text-scrollable{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
    }
    .poster-image-holder{
        padding: .3rem;
        gap:0;
    }
    .image-gallery{
        grid-template-columns:1fr;
    }
  }