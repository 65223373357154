.tuberculosis-body{
    height: 100%;
    overflow-y: scroll !important;
    position: relative;
    top: 0;
    margin: 0;
}
.sidebar-content-tuber{
    position:fixed;
    overflow: none !important;
    height:100%;
}
.sidebar-tuber{
    height:100% !important;
    overflow: none !important;
}

.content-tuber{
    z-index:0;
    width: 100%;
    background: rgb(203,65,80);
    background: linear-gradient(177deg, rgba(203,65,80,1) 4%, rgba(198,198,197,1) 82%);
    height: 100%;
    background-attachment: local;
}
.inner-content{
    margin: 0;
    padding: 0;
}
.timeline__header{
    text-align: center;
    color: white;
    padding: 5rem .5rem;
    line-height: 1.2;
}
// .timeline p, .timeline h2{
//     padding-left:0;
// }
//TIMELINE
.timeline {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1rem;
    display: grid;
    grid-template-columns: .1rem 1fr 1fr;
    color: #fff;
  }
  .timeline img{
      width: 100%;
      border-radius: .5rem;
      border: 5px solid rgba(0, 0, 0, 0.25);
  }
  
  .timeline__component {
    margin: 0 var(--space-xs) var(--space-xs) var(--space-xs);
  }
  
  
  .timeline__component--bottom {
    margin-bottom: 0;
  }
  
  .timeline__middle {
    position: relative;
    background: #fff;
  }
  
  .timeline__point {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
  }
  
  .timeline__point--bottom {
    top: initial;
    bottom: 0;
  }
  
  .timeline__date{
    font-size: var(--font-size-medium);
  }
  
  .timeline__title {
    margin: 0;
    font-size: 1.15rem;
    font-weight: bold;
  }
  
  .timeline__paragraph {
    line-height: 1.5;
  }

  .timeline__silent{
      display:none;
  }

  @media only screen and (max-width: 760px) {
      .timeline{
          grid-template-columns: .1rem 1fr;
      }
      .timeline__silent{
        display: block;
    }
    .timeline__filler{
        display: none;
    }
    .timeline__last{
       margin-bottom: 5rem;
    }
    .tuberculosis-body{
        display:flex;
    }
  }