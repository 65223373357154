

@font-face {
    font-family: "GT Alpina";
    src: url(/assets/fonts/GTAlpina-Light.otf);
    font-style: normal;
    font-weight: 300
 }
 @font-face {
    font-family: "GT Alpina";
    src: url(/assets/fonts/GTAlpina-LightItalic.otf);
    font-style: italic;
    font-weight: 300
 }
 @font-face {
    font-family: "GT Alpina";
    src: url(/assets/fonts/GTAlpina-Regular.otf);
    font-style: normal;
    font-weight: 400
 }
 @font-face {
    font-family: "GT Alpina";
    src: url(/assets/fonts/GTAlpina-RegularItalic.otf);
    font-style: italic;
    font-weight: 400
 }
 @font-face {
    font-family: "GT Alpina Typewriter";
    src: url(/assets/fonts/GTAlpinaTypewriter-RegularItalic.otf);
    font-style: italic;
    font-weight: 400
 }
 @font-face {
    font-family: "GT Alpina Typewriter";
    src: url(/assets/fonts/GTAlpinaTypewriter-Regular.otf);
    font-style: normal;
    font-weight: 400
 }

:root{
    --font-copy: "GT Alpina", serif;
    --font-source: "GT Alpina Typewriter", monospace;

    --font-size-base: 18px;
    --font-size-small: 1rem;
    --font-size-medium: 1.3rem;
    --font-size-big: clamp(4rem, 10vw, 20rem);
    --font-size-large: clamp(1.6rem, 2vw, 2.5rem);

    --letter-space-base: -.02rem;
    --letter-space-big: calc(var(--font-size-big) / 10);
}



:root{
    font-size: var(--font-size-base);
}
::selection{
    color: #000;
    background: rgba(255, 255, 0, .99);
    background: none;
}
h1,h2,h3,p,i{
    cursor: default;
}
h1,h2,h3,p,a,span,i{
    &::selection{
        color: #000;
        background: rgba(255, 255, 0, .99);
    }
}

h1{
    font-family: var(--font-copy);
    font-weight: 400;
    font-style: italic;
    font-size: var(--font-size-big);
    text-align: left;
    letter-spacing: -.2vw;
}
h2{
    font-family: var(--font-copy);
    font-weight: 400;
    font-size: var(--font-size-large);
    line-height: calc(var(--font-size-large) * 0.83);
    margin: 1rem 0 2rem 0;
    padding-left: var(--space-s);
}
h3{
    font-family: var(--font-copy);
    font-weight: 300;
    font-style: italic;
    font-size: var(--font-size-medium);
    line-height: calc(var(--font-size-medium) * 1.05);
    margin: var(--space-s) 0 var(--space-m) 0;
}
p{
    font-family: var(--font-copy);
    font-size: var(--font-size-small);
    line-height: calc(var(--font-size-small) * 1.23);
    letter-spacing: var(--letter-space-base);
    font-variant-ligatures: common-ligatures;
    padding-left: var(--space-s);
    hyphens: auto;
    hyphenate-limit-chars: auto 3;
    hyphenate-limit-lines: 4;
}
p:focus{
    background: yellow;
}
a{
    text-decoration: underline;
    color: var(--color-black);
}
.image-credits{
    margin-top: 1rem;
    padding: 0;
}
.image-credits *{
    font-family: var(--font-source);
    font-size: .75rem;
    line-height: .75rem;
    margin-bottom: .45rem;
    padding: 0;
}
.source-link{
    font-family: var(--font-source);
    font-size: .75rem;
    line-height: .9rem;
    margin-bottom: .45rem;
}