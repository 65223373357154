/* POSTER OVERLAY */
.overlay-poster{
    background-color: rgba(255,255,255,0.8);
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.overlay-poster-content{
    text-align: center;
}
.overlay-images{
    display:flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5rem;
}
.overlay-images >*{
    max-width: 10rem;
    width: 100%;
    height: 100%;
    margin-right: 1rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
}
.overlay-images >*:last-child{
    margin:0;
}
.button-influenza{
    text-align: center;
    text-decoration: none;
    color: #fff;
    padding: var(--space-xxs) var(--space-m);
    border-radius: 2rem;
    background-color: var(--color-black);
    cursor: pointer;
}



#canvas{
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
}
.poster-wrapper{
    width: 100%;
    max-width: 400px;
    padding: 2rem;
}
.generator-content{
    position: relative;
    overflow-x: hidden;
    // overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.poster-generator{
    width: 100%;
    display: flex;
    justify-content: center;
}
.canvas-container {
    margin: auto !important;
    width: 100% !important;
}
canvas{
    margin: auto;
}


/* CONTROLS */

.generator-controls{
    position: absolute;
    top: var(--space-xxs);
    left: var(--space-xxs);
    padding: .2rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    z-index: 8;
    background: white;
}
.poster-control-icon{
    width: 2rem;
    height: 2rem;
    margin: .3rem;
    display: block;
    cursor: pointer;
}
.poster-control-icon svg{
    width: 2rem;
    height: 2rem;
    display: block;
}
.image-dropdown-container{
    position: absolute;
    display: none;
    height: calc(70vh - calc(var(--space-xxs) * 4));
    background: white;
    top: var(--space-xxs);
    left: calc(var(--space-xxs) + 4rem);
    padding: .5rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    z-index: 10;
    overflow-y: auto;
}
.image-dropdown{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: .2rem;
    grid-row-gap: .5rem;
    max-width: 20rem;
    padding-bottom: 2rem;
}
.image-dropdown-container::-webkit-scrollbar{
    display: none;
}
.image-dropdown img{
    width: 100%;
    height: 7rem;
    object-fit: contain;
    background-color: rgb(240, 240, 240);
    border-radius: .5rem;
}
.image-dropdown-title{
    text-align: center;
    height: fit-content;
    cursor: pointer;
    text-decoration: none;
}
.gallery-subtitle{
    margin: 2rem 0 1rem 0;
    padding: 0;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
}

/* RESPONSIVE */
@media only screen and (min-width: 1280px) {
    .poster-wrapper{
        max-width: 500px;
    }
}
@media only screen and (max-height: 800px) {
    .poster-wrapper{
        max-width: 300px;
    }
}