.map-controls{
    position: fixed;
    top: var(--space-xs);
    left: var(--space-xs);
    z-index: 90;
}
.control-plus, .control-minus{
    cursor:pointer;
    width: 3rem;
    height: 3rem;
    text-decoration: none;
}
.control-plus svg, .control-minus svg{
    width:100%;
    max-width: 3rem;
    max-height:3rem;
    height:100%;
}
#map{
    z-index:9;
}