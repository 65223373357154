.container-text-content{
    position: relative;
    width:100%;
    background: var(--color-bg);
    padding: var(--space-xs);
    overflow-y: auto;
}
.content-text{
    width: 100%;
    margin: 0 auto;
    max-width: 75ch;
}
.interview-container > *{
    display: flex;
}
.interview-container p{
    padding: 0;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: calc(var(--font-size-medium) * 1.25);
}
.interview-content{
    margin-left: var(--space-m);
}

.question .interview-copy{
    font-style: italic;
}
.interview-copy{
    margin-bottom: var(--font-size-medium);
}
.interview-media{
    display: flex;
    justify-content: center;
    margin-bottom: var(--font-size-medium);
}
.interview-media > *{
    max-width: 10rem;
    height: 100%;
}
.interview-media img{
    margin-right: var(--space-xs);
}
.prefix{
    min-width: 3rem;
}